import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { i18n } from 'config/i18n';
import { defaultTheme } from 'config/theme/constants';
import getTokens from 'config/theme/getTokens';
import { mapAppPreferenceToSettings } from 'core/mapper';
import {
  AppPreference,
  FwSettingsProviderProps,
  fwSettingsProviderPT,
} from 'core/model';
import { defaultLocale } from 'core/utils/constant';
import { sendToSW, swEventActions } from 'core/utils/serviceWorker';
import {
  getTheme as readTheme,
  readSettings,
  setTheme as storeTheme,
  storeSettings,
} from 'core/utils/storage';

import { FwSettingsContext } from './FwSettingsContext';

//
//
// todo fetch fasterclient preferences if no settings yet
// todo types in Settings type (e.g. MODES instead of string)
//
//

const FwSettingsProvider: FC<FwSettingsProviderProps> = ({
  children,
  ...initialSettings
}) => {
  // initialise settings from storage (if exists) or props (if no storage)
  const settingsRef = useRef(
    new FwSettingsProviderProps({
      accent: defaultTheme.accent,
      direction: defaultTheme.dir,
      isProd: process.env.NODE_ENV === 'production',
      language: defaultLocale,
      localMode: false,
      theme: defaultTheme.mode,
      ...(readSettings() || initialSettings),
    })
  );

  const [settings, setSettings] = useState<FwSettingsProviderProps>({});

  const dispatchSettings = useCallback(
    (
      partialSettings?: FwSettingsProviderProps,
      toggleColorMode?: () => void
    ) => {
      const fullSettings = new FwSettingsProviderProps(
        _.assign(
          settingsRef.current,
          _.pickBy(partialSettings, (value) => value !== undefined)
        )
      );

      // apply language change
      const lang = fullSettings.language;

      if (lang && lang !== i18n.language) {
        i18n.changeLanguage(lang);
      }

      // apply theme mode change
      const themeMode = fullSettings.theme;
      const currentMode = readTheme();

      if (themeMode && themeMode !== currentMode) {
        storeTheme(themeMode);

        if (currentMode) {
          toggleColorMode?.();
        }
      }

      // update storage, ref and state
      storeSettings(fullSettings);
      settingsRef.current = fullSettings;
      setSettings(fullSettings);
    },
    []
  );

  const setLocalMode = useCallback(
    (localMode: boolean, swOnly = false) => {
      if (!swOnly) {
        // store in settings (in context and local storage)
        dispatchSettings({ localMode });
      }

      // notify service worker of change
      sendToSW(swEventActions.setLocalNavigation, localMode);
    },
    [dispatchSettings]
  );

  useEffect(() => {
    // at mount, make sure initial settings are saved to storage and state
    dispatchSettings();
  }, [dispatchSettings]);

  const dispatchAppPreference = useCallback(
    (partialAppPref: AppPreference) => {
      // remove null values coming from DB
      const validAppPref = _.pickBy(partialAppPref, (value) => value !== null);

      // map app preferences to settings
      const settings = mapAppPreferenceToSettings(validAppPref);

      // dispatch settings
      dispatchSettings(settings);
    },
    [dispatchSettings]
  );

  const { accent, direction } = settings;
  const appliedTheme = extendTheme({
    direction,
    ...getTokens(accent),
  });

  return (
    <FwSettingsContext.Provider
      value={{
        ...settings,
        dispatchAppPreference,
        dispatchSettings,
        setLocalMode,
      }}
    >
      <ChakraProvider theme={appliedTheme}>{children}</ChakraProvider>
    </FwSettingsContext.Provider>
  );
};

FwSettingsProvider.propTypes = fwSettingsProviderPT;

export { FwSettingsProvider };
