import { ColorModeContextType } from '@chakra-ui/react';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';

import { colors, getPalettes } from './colors';
import components from './components';
import { fonts } from './fonts';
import { shadows } from './shadows';

const getTokens = (accent: string) => ({
  // static tokens: color palettes, shadows, etc.
  fonts,
  colors: getPalettes(accent),
  components,
  // semantic tokens: theme-dependent color variables, shadows, etc.
  semanticTokens: {
    colors,
    shadows,
  },
  // global styles
  styles: {
    global: (props: ColorModeContextType | StyleFunctionProps) => ({
      'body > div > div > div *, body > div#root > div > *': {
        // in firefox
        scrollbarWidth: 'thin',
        // in chrome
        '&::-webkit-scrollbar': {
          width: '7px',
          height: '7px',
        },
        '&::-webkit-scrollbar-track': {
          background: mode('gray.100', 'gray.900')(props),
          borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: mode('blackAlpha.300', 'whiteAlpha.400')(props),
          transition: 'color .2s ease',
          borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: mode('blackAlpha.500', 'whiteAlpha.600')(props),
        },
      },
      input: {
        _disabled: {
          opacity: '0.6 !important',
        },
      },
      label: {
        _disabled: {
          opacity: '0.6 !important',
        },
      },
    }),
  },
});

export default getTokens;
