import _ from 'lodash';
import { arrayOf, InferProps, string, func } from 'prop-types';
import React, { FC } from 'react';

import { FwArticle, FwHighlight, FwSegment, useFwTheme } from 'components/base';

const FwCard: FC<Props> = ({ content, borderColor, searchText, onClick }) => {
  const { accent } = useFwTheme();

  const highlightedContent = searchText
    ? _.map(content, (texts) =>
        _.map(texts, (text) => (
          <FwHighlight key={text} text={text} highlight={searchText} />
        ))
      )
    : content;

  return (
    <FwSegment borderColor={borderColor || accent} onClick={onClick}>
      <FwArticle small content={highlightedContent} />
    </FwSegment>
  );
};

const propTypes = {
  borderColor: string,
  content: arrayOf(arrayOf(string)),
  searchText: string,
  onClick: func,
};

export type Props = InferProps<typeof propTypes>;

FwCard.propTypes = propTypes;

export default FwCard;
