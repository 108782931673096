import { TFunction } from 'i18next';
import _ from 'lodash';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
// import { Box as Visibility } from '@chakra-ui/react';

import documentApi from 'api/document/documentApi';
import { useApi } from 'api/useApi';
import { FwGrid, useFwStore } from 'components/base';
import {
  DataProps,
  fieldTimeout,
  getAutoSaveInfo,
  getExtendDocs,
  getSubmitData,
  isSaveAndChange,
  isSaved,
  isValidResponse,
  reducer,
  renderModal,
  updateFields,
} from 'components/doc/function/document';
import { Document } from 'core/model';
import { FIELD_KEY, FIELD_TYPE } from 'core/utils/constant';
import { MODAL_TYPES } from 'core/utils/constants';
import utils from 'core/utils/utils';
import {
  isValidDocument,
  defaultModal,
  getModalOptions,
} from 'core/utils/utilsModal';

import TemplateHeader from '../form/components/template/components/TemplateHeader';
import ClickablesSegment from './clickables/ClickablesSegment';
import {
  getClickables,
  getDocData,
  getDocDataExtended,
  getVisibleElements,
  setInvalidInputKey,
  Suggestions,
  updateState,
} from './helpers';
import DocModal from './modal/DocModal';
import Steps from './steps/Steps';

const Doc = ({
  publicly,
  coords,
  autosave,
  document,
  linkTemplateID,
  localMode,
  shouldReload,
  reFetchDoc,
  viewingUsers,
  setStickyFunc,
  showStickyFunc,
  hideTemplateHeader,
}: any) => {
  const { t } = useTranslation();
  const store = useFwStore();

  // refs, do not replace useState by useRef https://github.com/facebook/react/issues/14490
  const autosaveRef = useRef(autosave);
  const previousTimeouts = useRef({});
  const unmountingRef = useRef(false);
  const initialUsers = useRef(viewingUsers || []);
  const shouldReloadRef = useRef(shouldReload);
  const documentRef = useRef(_.cloneDeep(document));
  const linkTemplateIDRef = useRef(linkTemplateID);
  const invalidInputKeyRef = useRef();

  const [clickablesRef] = useState(() => ({
    current: getClickables(documentRef.current),
  }));
  const [docDataRef] = useState(() => ({
    current: getDocData(documentRef.current, store),
  }));
  const [docDataExtendedRef] = useState(() => ({
    current: getDocDataExtended(documentRef.current, docDataRef.current),
  }));
  const [visibleElementsRef] = useState(() => ({
    current: getVisibleElements(documentRef.current, docDataRef.current, store),
  }));

  // state
  const [args, setArgs] = useState([]);
  const [currentDocument, setCurrentDocument] = useState({});
  const [
    {
      actionable,
      redirect,
      docData,
      clickables,
      exists,
      prevDocument,
      visibleElements,
      modal: { isOpen, options },
      invalidInputKey,
      invalidTrigger,
      invalidStepID,
      loadingInputKeys,
    },
    dispatch,
  ] = useReducer(reducer, {
    actionable: true,
    clickables: getClickables(documentRef.current),
    docData: docDataRef.current,
    exists: documentRef.current.active,
    prevDocument: {
      ...documentRef.current,
      data: JSON.stringify(docDataExtendedRef.current),
    },
    visibleElements: visibleElementsRef.current,
    modal: defaultModal,
  });

  const { response, pending } = useApi(
    args.length ? (exists ? documentApi.putAS : documentApi.post) : undefined,
    args
  );

  // unmount management
  useEffect(() => {
    return () => {
      unmountingRef.current = true;
    };
  }, []);

  const safeDispatch = useCallback((newState) => {
    if (!unmountingRef.current) {
      dispatch(newState);
    }
  }, []);

  // trigger on load search functions
  useEffect(() => {
    Suggestions.getSuggestions(
      dispatch,
      documentRef.current,
      docDataRef.current,
      undefined,
      visibleElementsRef.current,
      store
    );
  }, [documentRef, docDataRef, store, visibleElementsRef]);

  // update coords data
  useEffect(() => {
    if (publicly && coords) {
      const { longitude, latitude } = coords;
      const long = `${longitude}`;
      const lat = `${latitude}`;
      const newData = {};

      // add/update the value of coordination if have any change
      if (docDataRef.current[FIELD_KEY.longitude] !== long) {
        newData[FIELD_KEY.longitude] = long;
      }

      if (docDataRef.current[FIELD_KEY.latitude] !== lat) {
        newData[FIELD_KEY.latitude] = lat;
      }

      if (!_.isEmpty(newData)) {
        updateState(
          newData,
          documentRef,
          docDataRef,
          visibleElementsRef,
          invalidInputKeyRef,
          safeDispatch,
          store
        );
      }
    }
  }, [publicly, coords, docDataRef, safeDispatch, store, visibleElementsRef]);

  // concurrency events
  useEffect(() => {
    if (initialUsers.current.length > 0) {
      // some users are already viewing this doc before first render
      safeDispatch({
        modal: {
          isOpen: true,
          options: getModalOptions({
            name: 'Warning',
            message:
              'Other users are viewing this document, continuing may disrupt their operations',
            ok: 'Ok',
            type: MODAL_TYPES.warning,
          }),
        },
      });
    }
  }, [safeDispatch]);

  useEffect(() => {
    if (shouldReload && actionable) {
      // another user edited this document
      shouldReloadRef.current = shouldReload;

      safeDispatch({
        modal: {
          isOpen: true,
          options: getModalOptions({
            name: 'Warning',
            message:
              'This document has been edited by another user. The page will be reloaded',
            ok: 'Ok',
            type: MODAL_TYPES.warning,
          }),
        },
      });
    }
  }, [shouldReload, actionable, safeDispatch]);

  // receive response from api call
  useEffect(() => {
    if (isValidResponse(response)) {
      // prevent further api call by clearing args
      setArgs([]);

      // update state
      safeDispatch({
        actionable: true,
        clickables: getClickables(_.cloneDeep(currentDocument)),
        exists: true,
        prevDocument: _.cloneDeep(currentDocument),
      });
    }
  }, [response]);

  // send api request
  const triggerApiCall = useCallback(
    (formSubmit, fields?) => {
      // compute api arguments
      const newArgs = exists
        ? [getAutoSaveInfo(formSubmit, fields, 'documentID')]
        : [null, formSubmit, undefined];

      // trigger useApi hook
      setArgs(newArgs);
    },
    [exists, setArgs]
  );

  // on document button click
  const applyButtonEffect = useCallback(
    async (buttonEffectTrigger, hideModal?) => {
      const result = await buttonEffectTrigger();

      if (!result) {
        if (hideModal) {
          hideModal();
        }

        // update state
        safeDispatch({ actionable: true });
      } else {
        // redirect
        safeDispatch({ redirect: result });
      }
    },
    [safeDispatch]
  );

  const autosaveButtonEffect = useCallback(
    async (options) => {
      const clickable = _.filter(clickables, function (clickable) {
        return clickable.modal && clickable.modal.modalId === options.modalId;
      });

      if (clickable[0].toStatus) {
        // form to submit to the API
        const formSubmit = {
          documentID: prevDocument.documentID,
          template: { templateId: prevDocument.template.templateId },
          status: clickable[0].toStatus,
        };

        setCurrentDocument({
          ...prevDocument,
          template: documentRef.current.template,
          status: clickable[0].toStatus,
        });

        triggerApiCall(formSubmit, ['template', 'status']);
      }
    },
    [clickables, prevDocument, triggerApiCall]
  );

  const onButtonClick = useCallback(
    async (validateDoc, options, buttonEffect) => {
      safeDispatch({ actionable: false });
      let invalidInputKey;

      if (validateDoc) {
        // validate data in document
        const visibleInputs = utils.getVisibleInputsFromSteps(
          visibleElementsRef.current.steps,
          true
        );

        invalidInputKey = isValidDocument(t, visibleInputs, docDataRef.current);
      }

      if (invalidInputKey) {
        // document data was not validated
        safeDispatch({
          ...setInvalidInputKey(
            invalidInputKey,
            invalidInputKeyRef,
            visibleElementsRef
          ),
          actionable: true,
        });
      } else {
        let buttonEffectTrigger;
        if (autosaveRef.current) {
          buttonEffectTrigger = autosaveButtonEffect;
        } else {
          buttonEffectTrigger = async () =>
            await buttonEffect(
              documentRef,
              docDataRef,
              safeDispatch,
              linkTemplateIDRef.current,
              localMode
            );
        }
        if (options) {
          // show modal before applying button effect
          safeDispatch({
            modal: {
              isOpen: true,
              options: { ...options, buttonEffect: buttonEffectTrigger },
            },
          });
        } else {
          autosaveRef.current
            ? autosaveButtonEffect(options)
            : await applyButtonEffect(buttonEffectTrigger);
        }
      }
    },
    [
      applyButtonEffect,
      clickables,
      docDataRef,
      prevDocument,
      visibleElementsRef,
      safeDispatch,
    ]
  );

  const getCurrentData = (docDataRef, documentRef) => {
    const dataSubmit = _.cloneDeep(docDataRef.current);

    const allInputs = utils.getDistinctFieldsFromTemplates([
      documentRef.current.template,
    ]);

    _.forEach(allInputs, function ({ key, type }) {
      if (type === FIELD_TYPE.collection) {
        // remove empty object if any
        const subFieldValues = _.reject(dataSubmit[key], _.isEmpty);

        // keep info of length
        dataSubmit[key] = subFieldValues.length;
      } else if (type === FIELD_TYPE.reference) {
        // keep inputValue
        dataSubmit[key] = dataSubmit[key].inputValue;
      }
    });

    return dataSubmit;
  };

  // on input data change
  const onChangeData: (
    e: ChangeEvent,
    data: { name: string; value: string | object; fillData?: object }
  ) => void = useCallback(
    (_e, { fillData, ...htmlData }) => {
      const newDocData = fillData || utils.getDocDataFromNameValue(htmlData);

      updateState(
        newDocData,
        documentRef,
        docDataRef,
        visibleElementsRef,
        invalidInputKeyRef,
        safeDispatch,
        store
      );

      if (autosaveRef.current && fillData) {
        const number = response
          ? response.data.number
          : documentRef.current.number;

        const prevData = prevDocument.data ? JSON.parse(prevDocument.data) : {};
        const currentData = getCurrentData(docDataRef, documentRef);
        const submitData = getSubmitData(prevData, currentData);

        const submitKeys = ['template', 'data'];
        const fillKeys = Object.keys(fillData).toString();

        const extendDocs = getExtendDocs(
          _.cloneDeep(docDataRef.current),
          documentRef.current,
          linkTemplateID
        );

        const formSubmit = new Document({
          documentID: prevDocument.documentID,
          data: JSON.stringify(submitData),
          lastEdit: undefined,
          number: undefined,
          template: {
            templateId: prevDocument.template.templateId,
            position: undefined,
          },
        });

        // additional data when using extendDocs
        if (extendDocs.length != 0) {
          formSubmit.extendDocuments = extendDocs;
          submitKeys.push('extendDocuments');
        }

        // additional data when using post api method
        if (!exists) {
          formSubmit.active = exists;
          formSubmit.status = prevDocument.status;
          formSubmit.number = number;
          formSubmit.metaData = {
            ...prevDocument.metaData,
            NUMBER: number,
          };
        }

        setCurrentDocument({
          ...prevDocument,
          active: exists,
          data: JSON.stringify(currentData),
          number: number,
          template: documentRef.current.template,
          metaData: {
            ...prevDocument.metaData,
            NUMBER: number,
          },
        });

        fieldTimeout(
          previousTimeouts.current,
          fillKeys,
          submitKeys,
          formSubmit,
          triggerApiCall
        );
      }
    },
    [
      docDataRef,
      prevDocument,
      response,
      visibleElementsRef,
      invalidInputKeyRef,
      safeDispatch,
      store,
    ]
  );

  // on input focus out
  const onInputBlur = useCallback(
    (e) => {
      renderModal(e, documentRef, docDataRef, safeDispatch, store);
    },
    [docDataRef, safeDispatch, store]
  );

  // reset modal
  const dispatchAndHideModal = useCallback(
    (newState?) => {
      safeDispatch({ ...(newState || {}), modal: { ...defaultModal } });
    },
    [safeDispatch]
  );

  const hideModal = useCallback(() => {
    dispatchAndHideModal();
  }, [dispatchAndHideModal]);

  // on modal cancel button click
  const onModalCancel = useCallback(
    (isButtonModal) => {
      // modal was triggered by a button
      if (isButtonModal || autosaveRef.current) {
        // re-enable buttons
        safeDispatch({ actionable: true });
      }

      hideModal();
    },
    [hideModal, safeDispatch]
  );

  // on modal ok button click
  const onModalSubmit = useCallback(
    async (modalInputs, buttonEffect) => {
      if (shouldReloadRef.current) {
        reFetchDoc(documentRef.current.documentID);
      } else {
        // validate data inside popup
        const invalidInputKey = await isValidDocument(
          t,
          modalInputs,
          docDataRef.current
        );

        if (invalidInputKey) {
          safeDispatch({
            ...setInvalidInputKey(invalidInputKey, invalidInputKeyRef),
          });
        } else {
          // modal was triggered by a button
          if (buttonEffect) {
            if (autosaveRef.current) {
              buttonEffect(options);
              hideModal();
            } else {
              await applyButtonEffect(buttonEffect, hideModal);
            }
          } else {
            hideModal();
          }
        }
      }
    },
    [
      applyButtonEffect,
      hideModal,
      options,
      docDataRef,
      safeDispatch,
      reFetchDoc,
    ]
  );

  const getData = (document) => {
    return document && document.data && JSON.parse(document.data);
  };

  if (autosaveRef.current && currentDocument) {
    const saved = isSaved(response, pending);

    const tracker = isSaveAndChange(
      exists,
      saved,
      docDataExtendedRef.current,
      getData(prevDocument),
      getData(currentDocument)
    );

    _.forEach(visibleElements.steps, (steps) => {
      _.forEach(steps.contents, (content) => {
        const fields = content.fields;
        fields.length &&
          tracker &&
          tracker.length &&
          updateFields(exists, fields, tracker, getData(currentDocument));
      });
    });
  }

  const handleDocValidation = useCallback(
    (
      t: TFunction<'translation', undefined, 'translation'>,
      tempDocData: DataProps
    ) => {
      const visibleInputs = utils.getVisibleInputsFromSteps(
        visibleElementsRef.current.steps,
        true
      );

      return isValidDocument(t, visibleInputs, tempDocData);
    },
    [visibleElementsRef]
  );

  // render
  return redirect && redirect.location ? (
    <Redirect push to={redirect.location} />
  ) : (
    <FwGrid /*visibility
      continuous={false}
      once={false}
      offset={-150}
      onPassing={onPassing}
      onPassingReverse={onPassingReverse}*/
    >
      {clickablesRef.current.length > 0 && (
        <ClickablesSegment
          autosave={autosaveRef.current}
          publicly={publicly}
          actionable={actionable}
          viewersWarning={viewingUsers && viewingUsers.length > 0}
          clickables={autosaveRef.current ? clickables : clickablesRef.current}
          onButtonClick={onButtonClick}
          setStickyFunc={setStickyFunc}
          showStickyFunc={showStickyFunc}
        />
      )}
      {!hideTemplateHeader && (
        <TemplateHeader
          doc={documentRef.current}
          docData={docData}
          template={document.template}
          viewingUsers={viewingUsers}
        />
      )}
      <Steps
        docData={docData}
        documentID={documentRef.current.documentID}
        docNumber={documentRef.current.number}
        invalidInputKey={invalidInputKey}
        invalidStepID={invalidStepID}
        invalidTrigger={invalidTrigger}
        loadingInputKeys={loadingInputKeys}
        steps={visibleElements.steps}
        handleDocValidation={handleDocValidation}
        onChangeData={onChangeData}
        onInputBlur={onInputBlur}
      />
      <DocModal
        {...options}
        noDimmerClick
        open={isOpen}
        onSubmit={onModalSubmit}
        onCancel={onModalCancel}
        data={docData}
        visibleElements={visibleElements}
        invalidInputKey={invalidInputKey}
        loadingInputKeys={loadingInputKeys}
        onChangeData={onChangeData}
      />
    </FwGrid>
  );
};

export default Doc;
