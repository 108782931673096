import { Divider } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, Fragment } from 'react';

import { fwDataPT, FwDataProps } from 'core/model';
import { toArray } from 'core/utils/logic';

import FwDataItem from './FwData.Item';

const FwData: FC<FwDataProps> = ({ type, value }) => {
  const valuesToRender = toArray(value);

  return !valuesToRender ? null : (
    <>
      {_.map(valuesToRender, (val, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider />}
          {!val && valuesToRender.length > 1 ? (
            // prevent line to collapse when multiple value lines
            <br />
          ) : (
            <FwDataItem type={type} value={val} />
          )}
        </Fragment>
      ))}
    </>
  );
};

FwData.propTypes = fwDataPT;

export default FwData;
