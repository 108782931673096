// service worker implemented event actions
export enum swEventActions {
  setLocalNavigation = 'SETLOCALNAVIGATION',
  clearCache = 'CLEARCACHE',
}

export const sendToSW = (action: `${swEventActions}`, content?: unknown) => {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      action,
      content,
    });
  }
};
