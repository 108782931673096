import { chakra } from '@chakra-ui/react';
import React from 'react';

import { useFwSettings } from '../settings/useFwSettings';
import {
  containerStyle,
  glowStyle,
  green,
  red,
} from './FwLocalModeStatus.helpers';

// todo wip#664 consider browser internet connection status
// display a status indicator for network usage (green -> network access allowed ; red -> no network access allowed)
const FwLocalModeStatus = () => {
  const { localMode } = useFwSettings();

  return (
    <chakra.span {...containerStyle}>
      <chakra.span
        {...glowStyle}
        bg={localMode ? red : green}
        boxShadow={
          localMode
            ? `0 0 10px ${red}, 0 0 20px ${red}, 0 0 30px ${red}`
            : `0 0 10px ${green}, 0 0 20px ${green}, 0 0 30px ${green}`
        }
      />
    </chakra.span>
  );
};

export default FwLocalModeStatus;
