import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import { FwGrid, FwPageStoreProvider, useFwArea } from 'components/base';
import { CONTENT_TYPE } from 'core/utils/constant';
import scrollToAnchor from 'core/utils/scrollToAnchor';
import { sortByStepRowColumnKey } from 'core/utils/sort';
import utils from 'core/utils/utils';

// import CustomPageGrid from '../../custom-page/components/CustomPageGrid';
import CustomPageContent from '../../custom-page/components/CustomPageContent';
import { DataProps } from '../function/document';
// import BaseGrid from '../../grid/BaseGrid';

const CustomPage = ({
  pageContents,
  invalidInputKey,
  invalidTrigger,
  handleDocValidation,
  ...props
}: any) => {
  const { area } = useFwArea();

  // scroll to invalid input when invalidInputKey changes
  useEffect(() => {
    if (invalidInputKey && invalidTrigger?.timeStamp) {
      scrollToAnchor(`[name="${invalidInputKey}"]`);
      invalidTrigger.timeStamp = undefined;
    }
  }, [invalidInputKey, invalidTrigger]);

  const handleDocValidationScroll = useCallback(
    (t: TFunction, docData: DataProps) => {
      const inputKey = handleDocValidation(t, docData);

      if (inputKey) {
        scrollToAnchor(`[name="${inputKey}"]`);
      }

      return inputKey;
    },
    [handleDocValidation]
  );

  const visibleContents = _.map(
    sortByStepRowColumnKey(
      _.filter(
        pageContents,
        ({ areaOnly, areas, type }) =>
          utils.showInArea({ areaOnly, areas }, area) ||
          type === CONTENT_TYPE.section
      )
    ),
    ({ row, rowSpan, column, columnSpan, ...pc }) => ({
      key: pc.pageContentID,
      row,
      rowSpan,
      column,
      columnSpan,
      invalidInputKey,
      visible: pc?.visible,
      pc,
      handleDocValidationScroll: handleDocValidationScroll,
      ...props,
    })
  );

  // const contentKeyGetter = useCallback((input) => input.pageContentID, []);

  // const contentRenderer = useCallback(
  //   (pc) => (
  //     <CustomPageContent
  //       {...props}
  //       key={pc.pageContentID}
  //       area={area}
  //       pc={pc}
  //     />
  //   ),
  //   [area, props]
  // );

  return (
    <FwPageStoreProvider>
      <FwGrid itemComponent={CustomPageContent} items={visibleContents} />
    </FwPageStoreProvider>
  );
};

export default CustomPage;
