import { Box, VStack } from '@chakra-ui/react';
import { bool, InferProps, node } from 'prop-types';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FwButton,
  FwLink,
  FwModal,
  useFwArea,
  useFwAuth,
  useFwSettings,
  useFwTheme,
} from 'components/base';
import { FwModalProps } from 'core/model';
import { IDXDDB_STORES, deleteAllFromIdxdDB } from 'core/utils/idxdDB';
import { sendToSW, swEventActions } from 'core/utils/serviceWorker';
import { readSettings } from 'core/utils/storage';
import utils from 'core/utils/utils';

import FwLogo from '../../common/logo/FwLogo';

const logoutModal = {
  name: 'Log out',
  open: true,
  submitName: 'Yes',
  cancelName: 'No',
};

export const mvmtCurve = 'cubic-bezier(.19,1,.22,1) 1.25s';

export const getSidebarWidth = (compact) => (compact ? '48px' : '150px');

const FwSidebar: FC<Props> = ({
  children,
  compact = false,
  open = true,
  ...props
}) => {
  const { t } = useTranslation();
  const { area } = useFwArea();
  const { logout } = useFwAuth();
  const { bg } = useFwTheme();
  const { setLocalMode } = useFwSettings();

  const settings = readSettings();

  const [modal, setModal] = useState<FwModalProps>();

  const showModal = useCallback(() => {
    setModal(logoutModal);
  }, []);

  const closeModal = useCallback(() => {
    setModal(undefined);
  }, []);

  const handleLogout = useCallback(() => {
    if (settings.localMode) {
      // todo wip#664 ensure no data will be lost before
      deleteAllFromIdxdDB(IDXDDB_STORES.document);
      deleteAllFromIdxdDB(IDXDDB_STORES.request);
      setLocalMode(false);
      sendToSW(swEventActions.clearCache);
    }

    logout();
  }, [logout, setLocalMode, settings.localMode]);

  // todo should store those values somewhere so they can be reused?
  const width = getSidebarWidth(compact);

  const sidebarStyle = {
    h: '100%',
    w: width,
    transition: `margin ${mvmtCurve}, width ${mvmtCurve}`,
    ml: open ? 0 : `-${width}`,
    bg,
    zIndex: 9,
    boxShadow: 'base',
    overflow: 'hidden auto',
    overscrollBehavior: 'contain',
    sx: {
      msOverflowStyle: 'none' /*in IE and Edge*/,
      scrollbarWidth: 'none' /*in Firefox*/,
      '&::-webkit-scrollbar': { display: 'none' /*in Chrome*/ },
      '@media print': { display: 'none' },
    },
  };

  return (
    <Box {...sidebarStyle} {...props}>
      <VStack spacing={4}>
        <FwLink basic to={utils.buildUrlNav(area)}>
          {/*todo wip#125 remove style prop*/}
          <FwLogo compact={compact} />
        </FwLink>
        <FwButton
          primary
          rounded
          small
          basic={compact ? undefined : true}
          leftIcon={compact ? 'RiLogoutBoxRLine' : undefined}
          tooltip={compact ? t('common|Log out') : undefined}
          onClick={showModal}
        >
          {!compact && t('common|Log out')}
        </FwButton>
        {modal && (
          <FwModal
            {...modal}
            warning={
              settings.localMode
                ? t(
                    'You are currently in local mode. Logging out will deactivate it and result in the loss of any changes made while in local mode'
                  )
                : undefined
            }
            onSubmit={handleLogout}
            onCancel={closeModal}
          />
        )}
        {children}
      </VStack>
    </Box>
  );
};

const propTypes = {
  children: node,
  compact: bool,
  open: bool,
};

export type Props = InferProps<typeof propTypes>;

FwSidebar.propTypes = propTypes;

export default FwSidebar;
