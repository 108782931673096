import {
  arrayOf,
  bool,
  InferProps,
  instanceOf,
  number,
  oneOfType,
  string,
} from 'prop-types';

// https://gitlab.fasterlogistic.com/fastercom-repositories/fasterweb/-/wikis/Unstructured-data#view-contents-masking-grids

export enum DateViewType {
  day = 'DAY',
  week = 'WEEK',
  month = 'MONTH',
}

export enum TextTypes {
  script = 'SCRIPT',
  static = 'STATIC',
  fieldKey = 'FIELDKEY',
}

export class TextObject {
  text: string;
  type: `${TextTypes}`;
}

// mask views
const cardsViewTypes = {
  hideSearch: bool,
};

type CardsView = InferProps<typeof cardsViewTypes>;

const timelineViewTypes = {
  date: oneOfType([string, instanceOf(TextObject)]),
  start: number,
  end: number,
  hideConflictWarning: bool,
  hideDateSelect: bool,
  hideExpand: bool,
  hideTypeSelect: bool,
  slotMultiplier: number,
  type: `${DateViewType}`,
};

type TimelineView = InferProps<typeof timelineViewTypes>;

const agendaViewTypes = {
  slots: number,
  step: number,
  type: `${DateViewType}`,
};

type AgendaView = InferProps<typeof agendaViewTypes> & TimelineView;

export const mapViewTypes = {
  center: [number, number],
  zoom: number,
  geo: Object,
};

export type MapView = InferProps<typeof mapViewTypes>;

// mask documents
const commonDocumentTypes = {
  text: oneOfType([
    arrayOf(arrayOf(string)),
    arrayOf(arrayOf(instanceOf(TextObject))),
  ]),
};

type CommonDocument = InferProps<typeof commonDocumentTypes>;

const agendaDocumentTypes = {
  groupBy: string,
  start: arrayOf(string),
  length: string,
  end: arrayOf(string),
};

type AgendaDocument = InferProps<typeof agendaDocumentTypes> & CommonDocument;

const cardsDocumentTypes = {};

type CardsDocument = InferProps<typeof cardsDocumentTypes> & CommonDocument;

const mapDocumentTypes = {
  geo: arrayOf(string),
  longitude: string,
  latitude: string,
  markerText: string,
};
export type MapDocument = InferProps<typeof mapDocumentTypes>;

const timelineDocumentTypes = {};

type TimelineDocument = InferProps<typeof timelineDocumentTypes> &
  AgendaDocument;

export interface MaskStructure {
  compact?: boolean;
  view?: AgendaView & CardsView & MapView & TimelineView;
  document: AgendaDocument & CardsDocument & MapDocument & TimelineDocument;
}
export class MaskStructure implements MaskStructure {
  constructor(props?: MaskStructure) {
    Object.assign(this, props);
  }
}
