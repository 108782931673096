const cacheFileName = 'cache.json';

type CacheFileContent = {
  files: string[];
  urls: (
    | string
    | { origin: string; target: string; params: Record<string, unknown> }
  )[];
  backup?: string;
};

const swHeaderPrefix = 'X-Fwsw';
const swStoreHeader = `${swHeaderPrefix}-Store`;
const swUrlHeader = `${swHeaderPrefix}-Url`;

const swStoreValue = 'cache';

export {
  cacheFileName,
  CacheFileContent,
  swStoreHeader,
  swUrlHeader,
  swStoreValue,
};
