import _ from 'lodash';
import { arrayOf, InferProps, oneOf, oneOfType, string } from 'prop-types';

import { FIELD_TYPE } from 'core/utils/constant';

import { ctor } from '../helper';

const fwDataPT = {
  type: oneOf(_.values(FIELD_TYPE)),
  value: oneOfType([string, arrayOf(string)]),
};

type FwDataPT = InferProps<typeof fwDataPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwDataProps extends FwDataPT {}
class FwDataProps implements FwDataPT {
  constructor(props?: FwDataPT) {
    ctor(this, props);
  }
}

export { fwDataPT, FwDataProps };
