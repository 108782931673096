import ISO6391 from 'iso-639-1';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import baseApi from 'api/baseApi';
import documentApi from 'api/document/documentApi';
import languageApi from 'api/language/languageApi';
import operationApi from 'api/operation/operationApi';
import pageContentApi from 'api/page-content/pageContentApi';
import pageApi from 'api/page/pageApi';
import tableApi from 'api/table/tableApi';
import tableDataApi from 'api/tableData/tableDataApi';
import { useApi } from 'api/useApi';
import {
  useFwSettings,
  FwSpinner,
  useFwModule,
  FwModal,
} from 'components/base';
import FwProgress from 'components/base/elements/progress/FwProgress';
import { initializeTableCriteria } from 'components/tables/helpers/tableCriteriaHelpers';
import { LOCALES } from 'core/utils/constant';
import { sendToSW, swEventActions } from 'core/utils/serviceWorker';

import Preferences from './Preferences';
import {
  CacheFileContent,
  cacheFileName,
  swStoreHeader,
  swStoreValue,
  swUrlHeader,
} from './preferencesContainer.helpers';

const PreferencesContainer = () => {
  const { t } = useTranslation();

  const { dispatchAppPreference, setLocalMode } = useFwSettings();
  const { moduleRoutes } = useFwModule();

  const [options, setOptions] = useState(undefined);

  const [progress, setProgress] = useState({
    showModal: false,
    modalOptions: undefined,
    part1: undefined,
    part2: undefined,
  });

  const [getArgs] = useState([]);
  const { fetched, pending } = useApi(languageApi.getAll, getArgs);

  const getOptionByKey = (key: string) => ({
    key: key.toLowerCase(),
    value: key,
    text: ISO6391.getNativeName(key.toLowerCase()),
  });

  useEffect(() => {
    if (!pending && fetched) {
      const languageOptions = _.map(fetched?.languages, ({ key }) =>
        getOptionByKey(key)
      );

      // add default translation
      languageOptions.push(getOptionByKey(LOCALES['en']));

      // sorted alphabetically
      const sortLanguageOption = languageOptions.sort((a, b) =>
        a.key > b.key ? 1 : -1
      );

      setOptions(sortLanguageOption);
    }
  }, [fetched, pending]);

  const handleSetKey = useCallback(() => {
    // todo set in user instead or maybe refetch user from token
    // dispatchAppPreference({ appPreferenceID: key });
  }, []);

  // apply new preferences
  const handleEntityChange = useCallback(
    (data) => {
      // todo wip#585 value(s) will be overwritten by string values
      dispatchAppPreference(data);
    },
    [dispatchAppPreference]
  );

  // todo wip#664 refactor logic and nested fetches
  // todo wip#664 handle errors or browser losing internet connection
  const handleSetLocalMode = useCallback(
    async (localOnly: boolean) => {
      // todo wip#664 handle errors (returns true when connected to a router without internet connection)
      if (navigator.onLine) {
        // when going local only, prepare cached data for local-first behaviour
        if (localOnly) {
          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: true,
          }));

          const cachingHeaders = { [swStoreHeader]: swStoreValue };
          const cachingParams = { headers: cachingHeaders };

          // load file containing url addresses for static data
          const response = await fetch(`/${cacheFileName}`);

          // load static data
          if (
            response.ok &&
            response.headers.get('content-type').includes('application/json')
          ) {
            try {
              const { files, urls }: CacheFileContent = await response.json();

              // load static files
              for (let i = 0; i < files.length; i++) {
                const file = files[i];
                await fetch(file, { ...cachingParams });
              }

              // load static urls
              for (let i = 0; i < urls.length; i++) {
                const url = urls[i];
                const isString = typeof url === 'string';
                const path = isString ? url : url.origin;
                const params = isString
                  ? undefined
                  : url.target
                  ? {
                      ...url.params,
                      headers: {
                        [swUrlHeader]: url.target,
                        ...(url.params?.headers as Record<string, string>),
                      },
                    }
                  : url.params;

                await baseApi.get(path, {
                  ...params,
                  headers: {
                    ...cachingHeaders,
                    ...(params?.headers as Record<string, string>),
                  },
                });
              }
            } catch {
              // todo wip#664 handle errors
            }
          }

          // load dynamic data
          if (moduleRoutes) {
            // only work on custom modules
            const modules = moduleRoutes.filter((route) => !route.system);

            const pages = [];

            // trigger data fetches (service worker will cache all)
            for (let mIdx = 0; mIdx < modules.length; mIdx++) {
              // fetch pages
              const resM = await pageApi.getByModuleAndArea(
                modules[mIdx].key,
                null,
                cachingParams
              );
              pages.push(...resM?.data?.pages);
            }

            if (pages) {
              for (let pIdx = 0; pIdx < pages.length; pIdx++) {
                setProgress((prevProgress) => ({
                  ...prevProgress,
                  part1: (100 * (pIdx + 1)) / pages.length,
                  part2: 0,
                }));

                const pageContents = pages[pIdx].pageContent;

                for (let pcIdx = 0; pcIdx < pageContents.length; pcIdx++) {
                  // fetch page contents
                  const resPC = await pageContentApi.getById(
                    pageContents[pcIdx].pageContentID,
                    cachingParams
                  );
                  const pageContent = resPC?.data?.pageContent;

                  if (pageContent?.tableKey) {
                    // fetch corresponding table
                    const resT = await tableApi.getTable(
                      pageContent.tableKey,
                      cachingParams
                    );
                    const table = resT?.data?.table;

                    if (table) {
                      // fetch table data
                      const resTd = await tableDataApi.getMany(
                        tableDataApi.buildQuery(
                          table.key,
                          '',
                          initializeTableCriteria(
                            table,
                            '',
                            null,
                            [],
                            null,
                            null,
                            null,
                            null
                          )
                        ),
                        null,
                        table,
                        null,
                        null,
                        null,
                        cachingParams
                      );
                      const tableDataRows = resTd?.data?.tableData?.rows;

                      if (tableDataRows) {
                        for (
                          let tdrIdx = 0;
                          tdrIdx < tableDataRows.length;
                          tdrIdx++
                        ) {
                          setProgress((prevProgress) => ({
                            ...prevProgress,
                            part2: (100 * (tdrIdx + 1)) / tableDataRows.length,
                          }));

                          // todo wip#664 maybe just add all documents ID to an array and fetch later...
                          // ...this will improve code readability (decrease indentation)...
                          // ...and allow for more precise progress tracking

                          // fetch documents
                          await documentApi.getByID(
                            tableDataRows[tdrIdx].rowID,
                            '',
                            '',
                            '',
                            false,
                            cachingParams
                          );

                          // fetch operations
                          await operationApi.getManyByDocID(
                            tableDataRows[tdrIdx].rowID,
                            cachingParams
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          // store localMode status in settings
          setLocalMode(true);

          // close modal
          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: false,
            modalOptions: undefined,
          }));
        } else {
          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: true,
            modalOptions: { content: t('Synchronization...') },
          }));

          // opt out of localMode status in service worker
          setLocalMode(false, true);

          // try fetch remote backup endpoint for local requests
          let backupEndpoint: string;
          const response = await fetch(`/${cacheFileName}`);

          if (
            response.ok &&
            response.headers.get('content-type').includes('application/json')
          ) {
            try {
              const { backup }: CacheFileContent = await response.json();
              backupEndpoint = backup;
            } catch {
              // todo wip#664 handle errors
            }
          }

          // going back online, synchronize (resend data to backend through network)
          baseApi.sync((success) => {
            if (success) {
              // opt out of localMode status in settings
              setLocalMode(false);

              // notify service worker for a cache deletion
              sendToSW(swEventActions.clearCache);

              // close modal
              setProgress((prevProgress) => ({
                ...prevProgress,
                showModal: false,
                modalOptions: undefined,
              }));
            } else {
              // re-enter localMode in service worker
              setLocalMode(true, true);

              setProgress((prevProgress) => ({
                ...prevProgress,
                showModal: true,
                modalOptions: {
                  content: t(
                    'This operation requires a stable internet connection'
                  ),
                  cancelName: t('Ok'),
                  onCancel: () => {
                    setProgress((prevProgress) => ({
                      ...prevProgress,
                      showModal: false,
                      modalOptions: undefined,
                    }));
                  },
                },
              }));
            }
          }, backupEndpoint);
        }
      } else {
        setProgress((prevProgress) => ({
          ...prevProgress,
          showModal: true,
          modalOptions: {
            content: t('This operation requires a stable internet connection'),
            cancelName: t('Ok'),
            onCancel: () => {
              setProgress((prevProgress) => ({
                ...prevProgress,
                showModal: false,
                modalOptions: undefined,
              }));
            },
          },
        }));
      }
    },
    [t, moduleRoutes, setLocalMode]
  );

  const props = {
    options,
    handleSetKey,
    handleEntityChange,
    handleSetLocalMode,
  };

  return options ? (
    <>
      <Preferences {...props} />
      <FwModal
        noDimmerClick
        open={progress.showModal}
        content={
          progress.modalOptions ? undefined : (
            <>
              <>{t('common|Loading pages')}</>
              <FwProgress value={progress.part1} />
              <br />
              <>{t('common|Loading data')}</>
              <FwProgress type="success" value={progress.part2} />
            </>
          )
        }
        // state modal options must override other props
        {...progress.modalOptions}
      />
    </>
  ) : (
    <FwSpinner />
  );
};

export default PreferencesContainer;
