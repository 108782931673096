import { Document, Row, TableData } from 'core/model';

const mapDocsToTableData = (docs: Document[]): TableData => {
  // create table data wrapper
  const tableData = new TableData();

  // create table data rows from docs
  tableData.rows = docs?.map(
    (doc) =>
      new Row({
        // todo wip#664 missing property mappings?
        data: doc.data,
        key: doc.documentID,
        lastEdit: doc.lastEdit,
        lastView: doc.lastView,
        metaData: { ...doc.metaData, STATUS: doc.status },
        number: doc.number,
        rowID: doc.documentID,
        status: doc.status,
        viewed: true,
      })
  );

  //return
  return tableData;
};

export default mapDocsToTableData;
