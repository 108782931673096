import { TFunction } from 'i18next';
import _ from 'lodash';

import BaseApi, { BaseExternalApi } from 'api/baseApi';
import { FwStore } from 'components/base';
import { DataProps } from 'components/doc/function/document';
import { Column, Document, Rule, Table } from 'core/model';
import utils from 'core/utils/utils';

export type ScriptCallback = (
  r: Rule,
  d: DataProps,
  value: string | object | object[],
  key: string
) => void;

export type ParamsContext = {
  api?: unknown;
  callback?: ScriptCallback;
  docData?: DataProps;
  eventType?: string;
  field?: Column;
  indexItemChange?: number;
  injectDocData?: (arg: {
    name: string;
    value: string | object;
    fillData?: object;
  }) => void;
  inputKey?: string;
  isValidDocument?: (t: TFunction, docData: DataProps) => string;
  store?: FwStore /* todo wip#664 only optional for local mode */;
  t?: TFunction /* see #751 not available in all executions of execute script for now */;
  table?: Table;
  // todo wip#664 only for local mode
  docs?: unknown;
  mapDocsToTableData?: unknown;
  tableData?: unknown;
};

type ScriptParams = {
  context: ParamsContext;
};

type ApiParams = { local: boolean };

const api = (params: ApiParams) =>
  params && params.local ? BaseApi : BaseExternalApi;

const executeScript = (script: string, context: ParamsContext) => {
  const params: ScriptParams = { context };

  // create function from script
  const evalFunc = new Function(
    ..._.keys(context),
    ..._.keys(params) /* only one key */,
    script
  );

  // evaluate
  const evalResult = evalFunc(..._.values(context), params.context);

  // return
  return evalResult;
};

// const updateDataAndExecuteScript = (
//   script: string,
//   docData: DataProps,
//   key: string,
//   value: string | object | object[]
// ) => {
//   // update docData if has changed
//   if (key) {
//     docData[key] = value;
//   }

//   return executeScript(script, { docData });
// };

const applyScript = (
  rules: Rule[],
  {
    doc,
    data,
    handler,
    ...params
  }: Omit<
    ParamsContext,
    'api' | 'callback' | 'docData' | 'eventType' | 'inputKey'
  > & {
    doc: Document;
    data: DataProps;
    handler: ScriptCallback;
  }
) => {
  // prepare params
  const scriptParams = {
    ...params,
    api,
    callback: handler,
    docData: utils.getFullData(doc, data),
  };

  // loop
  _.forEach(rules, (r) => {
    // buid context
    const context: ParamsContext = {
      ...scriptParams,
      eventType: r.event.action,
      inputKey: r.event.key,
    };

    // evaluate
    const result = executeScript(r.script.data, context);

    if (result) {
      // apply
      _.forOwn(result, (value, key) => handler(r, data, value, key));
    }
  });
};

// export
export { api, executeScript, /*updateDataAndExecuteScript,*/ applyScript };
